var apex_ru = require("apexcharts/dist/locales/ru.json")

function Fix() {
    console.log(document.querySelectorAll(".apexcharts-datalabel").length)
    if (document.querySelectorAll(".apexcharts-datalabel")[0] !== undefined) {
        let labels_length = document.querySelectorAll(".apexcharts-datalabel").length/2;
        if (labels_length % 1 !== 0) {
            labels_length -= 0.5;
        }
        document.querySelectorAll(".apexcharts-datalabel")[0].style.transform = "translate(30px, 0px)";
        document.querySelectorAll(".apexcharts-datalabel")[labels_length-1].style.transform = "translate(-30px, 0px)";
        document.querySelectorAll(".apexcharts-datalabel")[labels_length].style.transform = "translate(30px, 0px)";
        document.querySelectorAll(".apexcharts-datalabel")[labels_length*2-1].style.transform = "translate(-30px, 0px)";
    }
}

export const options1 = {
    chart: {
        height: 250,
        type: 'area',
        toolbar: {
            show: false
        },
        sparkline: {
            enabled: false
        },
        zoom: {
            enabled: false
        },
        animations: {
            dynamicAnimation: {
                enabled: true,
                speed: 600
            }
        },
        events: {
            updated: () => {
                Fix()
            }
        },
        locales: [apex_ru], 
        defaultLocale: 'ru',
    },
    responsive: [
        {
            breakpoint: 1000,
            options: {
                chart: {
                    height: 200
                },
            }
        },
    ],
    series: [
        {
            name: "Объем продаж",
            // data: [7777.90, 5167.40, 14600.88, 19568.21, 13771.06, 13075.70, 19593.61, 15125.78, 13557.97, 27074.31, 41174.62, 29114.39]
            // data: [7777.90, 5167.40, 14600.88, 19568.21, 13771.06, 13075.70, 19593.61, 15125.78, 13557.97, 27074.31]
            data: [{x: 1711012589, y:2}, {x: 1711015589, y:2}, {x: 1711021589, y:2}, {x: 1711032589, y:2}, {x: 1711042589, y:2}]
            // data: [{x: 1711012589, y:2}, {x: 1711015589, y:4}, {x: 1711021589, y:2}, {x: 1711032589, y:2}, {x: 1711042589, y:2}]
        },
        {
            name: "Прибыль",
            // data: [931.59, 529.06, 1619.35, 3208.96, 1066.03, 1241.36, 1494.37, 973.15, 1051.97, 2177.73, 3072.12, 2207.63]
            // data: [931.59, 529.06, 1619.35, 3208.96, 1066.03, 1241.36, 1494.37, 973.15, 1051.97, 2177.73],
            data: [{x: 1711012589, y:1}, {x: 1711015589, y:1}, {x: 1711021589, y:1}, {x: 1711032589, y:1}, {x: 1711042589, y:1}]
            // data: [{x: 1711012589, y:1}, {x: 1711015589, y:2}, {x: 1711021589, y:1}, {x: 1711032589, y:1}, {x: 1711042589, y:1}]
        },
    ],
    colors: ['#6A6A6A', '#E3702C'],
    dataLabels: {
        enabled: true,
        style: {},
        offsetY: -6,
        background: {
            enabled: false
        },
        formatter: function (value) {
            return value + "$";
        },
    },
    stroke: {
        curve: 'straight',
        width: 1.5,
        linecap: "square"
    },
    grid: {
        show: false,
        padding: {
            left: 0,
            right: 0,
            bottom: -12
        }
    },
    xaxis: {
        type: 'datetime',
        labels: {
            show: false,
            style: {
                colors: "#6A6A6A"
            },
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            show: false,
        },
        tooltip: {
            enabled: false
        },
    },
    yaxis: [
        {
            show: false,
            // min: -Math.max(...Sales.volume)/2,
        },
        {
            show: false,
            opposite: true,
            // max: Math.max(...Sales.profit)*2
        }
    ],
    legend: {
        position: 'top',
        horizontalAlign: 'left',
        floating: true,
        // showForSingleSeries: false,
        // showForNullSeries: true,
        // showForZeroSeries: true,
        labels: {
            colors: "rgb(255, 255, 255)",
        },
        markers: {
            height: 12,
            width: 12
        },
        offsetY: -36,
        offsetX: -5,
        fontSize: "16px",
        fontFamily: "Inter"
    },
    title: {
        text: "⠀",
        align: 'left'
      },
    fill: {
        type: 'gradient',
        gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 100],
        gradientToColors: ["#1f1f1f", "#1f1f1f"]
        }
    },
    tooltip: {
        x: {
            format: 'MMM d',
        },
        y: {
            formatter: function (value) {
                return value + "$";
            },
        }
    }
}

export const options2 = {
    chart: {
        height: 280,
        type: 'line',
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        },
        animations: {
            dynamicAnimation: {
                enabled: true,
                speed: 600
            }
        },
        locales: [apex_ru], 
        defaultLocale: 'ru',
    },
    series: [
        {
            name: "Процент прогнозируемый",
            data: [{x:1704451053000, y:10}, {x:1704471363000, y:10}, {x:1704491673000, y:10}]
        },
        {
            name: "Процент фактический",
            data: [{x:1704451053000, y:9}, {x:1704471363000, y:9}, {x:1704491673000, y:9}]
        },
    ],
    colors: ['#B1B1B1', '#E3702C'],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'straight',
        width: 2,
        linecap: "square"
    },
    grid: {
        show: false,
        padding: {
            left: 0
        }
    },
    xaxis: {
        type: 'datetime',
        labels: {
            show: true,
            style: {
                colors: "#6A6A6A"
            },
            offsetY: -4,
            datetimeFormatter: {
                year: 'yyyy',
                month: 'd MMM',
                day: 'd MMM',
                hour: 'HH:mm'
            }
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            color: "#6A6A6A"
        },
        tooltip: {
            enabled: false
        },
        
    },
    yaxis: {
        show: true,
        axisBorder: {
            show: true,
            color: "#6A6A6A"
        },
        labels: {
            style: {
                colors: "#6A6A6A"
            },
            offsetX: -12
        },
    },
    legend: {
        show: false,
    },
    responsive: [
        {
            breakpoint: 1000,
            options: {
                chart: {
                    height: 200
                },
            }
        },
        {
            breakpoint: 1500,
            options: {
                chart: {
                    height: 240
                },
            }
        }
    ],
    title: {
        text: "Проценты прибыли",
        align: 'left',
        style: {
            color: "rgb(255, 255, 255)",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 16
        },
        offsetX: 37,
        offsetY: 20,
    },
    tooltip: {
        x: {
            format: 'MMM d',
        },
        y: {
            formatter: function (value) {
                return value + "%";
            },
        }
    }
};

export const options3 = {
    chart: {
        height: 280,
        type: 'bar',
        stacked: true,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        },
        animations: {
            dynamicAnimation: {
                enabled: true,
                speed: 600
            }
        },
        locales: [apex_ru], 
        defaultLocale: 'ru',
    },
    series: [
        {
            name: "Продажи",
            data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10]
        }
    ],
    colors: ['#E3702C', '#6A6A6A'],
    dataLabels: {
        enabled: false,
    },
    stroke: {
        curve: 'straight',
        width: 1,
        linecap: "square"
    },
    grid: {
        show: false,
        padding: {
            left: 40,
            bottom: -6,
        }
    },
    xaxis: {
        categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
        labels: {
            show: true,
            style: {
                colors: "#6A6A6A"
            },
            offsetY: -4
        },
        axisTicks: {
            show: false
        },
        axisBorder: {
            color: "#6A6A6A",
        },
        tooltip: {
            enabled: false
        },
    },
    yaxis: {
        show: true,
        axisBorder: {
            show: true,
            color: "#6A6A6A",
            // offsetX: -4,
        },
        labels: {
            style: {
                colors: "#6A6A6A"
            },
            offsetX: 28
        },
        floating: true,
    },
    legend: {
        show: false,
    },
    responsive: [
        {
            breakpoint: 1000,
            options: {
                chart: {
                    height: 200
                },
            }
        },
        {
            breakpoint: 1500,
            options: {
                chart: {
                    height: 240
                },
            }
        }
    ],
    title: {
        text: "Продажи",
        align: 'left',
        style: {
            color: "rgb(255, 255, 255)",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 16
        },
        offsetX: 40,
        offsetY: 20,
    },
    fill: {
        opacity: 0.2
    }
};

export const options4 = {
    chart: {
        height: 280,
        type: 'donut',
        stacked: true,
        toolbar: {
            show: false
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 600
            }
        },
        locales: [apex_ru], 
        defaultLocale: 'ru',
    },
    // series: [34, 16, 5],
    // series: [10, 10, 15],
    series: [1, 1, 1, -3],
    colors: ['#E37534', '#B1B1B1', '#636363'],
    labels: ['0-1 день', '2-3 дня', '3> дней'],
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        pie: {
            customScale: 0.8,
            donut: {
                size: "80%",

                labels: {
                    show: true,
                    value: {
                        show: true,
                        fontSize: '22px',
                        fontWeight: 500,
                        color: '#ffffff',
                        // offsetY: -10
                    },
                    total: {
                        show: true,
                        label: "Всего",
                        fontSize: "22px",
                        fontWeight: "bold",
                        color: "#fff",
                    },
                },
            },
        },
    },
    responsive: [
        {
            breakpoint: 1000,
            options: {
                chart: {
                    height: 200
                },
            }
        },
        {
            breakpoint: 1500,
            options: {
                chart: {
                    height: 240
                },
            }
        }
    ],
    legend: {
        show: false,
    },
    stroke: {
        width: 3,
        colors: ["#1f1f1f"]
    },
    grid: {
        show: false,
        padding: {
        }
    },
    title: {
        text: "Продолжительность",
        align: 'center',
        style: {
            color: "rgb(255, 255, 255)",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: 16
        },
        offsetX: 0,
        offsetY: 20,
    }
}

export const stylesSelect = {
        control: (provided, state) => {
            return {
                ...provided,
                boxShadow: "none",
                border: "1px solid #828282",
                color: "#fff",
                minHeight: "40px",
                maxHeight: state.isFocused ? "110px" : "40px",
                width: "220px",
                zIndex: "2",
                backgroundColor: "#1f1f1f",
                marginRight: '6px',
                '&:hover': state.isFocused ? { borderColor: '#E3702C' } : {borderColor: '#B3B3B3'},
                cursor: "pointer",
            }
        },

        SelectContainer: (provided, state) => ({
            ...provided,
            height: "100px"
        }),
        valueContainer: (provided, state) => {
            const account_menu_open = state.isFocused ? true : false;
            return {
                ...provided,
                // maxHeight: '100px',
                maxHeight: account_menu_open ? '100px' : '38px',
                overflow: "auto",
                '&::-webkit-scrollbar': {
                    width: "0px"
                },
            }
        },
        indicatorsContainer: (provided, state) => ({
            ...provided,
            // maxHeight: state.isFocused ? '100px' : '30px',
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            // maxHeight: state.isFocused ? '100px' : '30px',
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#1f1f1f",
            border: "1px solid #828282",
            zIndex: "2",
        }),
        option: (provided, state) => ({
            ...provided,
            color: "#fff",
            // backgroundColor: state.isFocused ? "#CD7D4E" : "none"
            backgroundColor: state.isFocused ? "#E37534" : "none",
            // cursor: "pointer",
        }),
        input: (provided, state) => ({
            ...provided,
            color: "#fff",
        }),
        // noOptionsMessage: (provided, state) => ({
        //     ...provided,
        //     color: "#fff",
        //     // backgroundColor: state.isFocused ? "#CD7D4E" : "none"
        //     backgroundColor: state.isFocused ? "#E37534" : "none",
        //     // cursor: "pointer",
        // }),
      
        // input: (provided, state) => ({
        // ...provided,
        // margin: '0px',
        // }),
        // indicatorSeparator: state => ({
        // display: 'none',
        // }),
        // indicatorsContainer: (provided, state) => ({
        // ...provided,
        // height: '30px',
        // }),
        // option: (provided, state) => ({
        //     ...provided,
        //     color: "#fff",
        // }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: "#636363",
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: "#fff",
        }),
    }
