import React, { useEffect, useRef } from 'react';

import '../styles/app.css';
import Header from './header';
import Main from './main';
import Footer from './footer';
import Login from './login';
import Finance from './finance';
import { HistoryPage } from './history';
import BuffOrders from './bufforders';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const App = () => {
    return (
        <>
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" element={<Main/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/finance" element={<Finance/>} />
                <Route path="/history" element={<HistoryPage />} />
                {/* <Route path="/my_orders" element={<BuffOrders/>} /> */}
            </Routes>
        </BrowserRouter>
        <Footer/>
        </>
    )
}

export default App;