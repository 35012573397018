import React, { useState, useEffect, createElement } from "react";
import classes from './history.module.css';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import Gradient from 'javascript-color-gradient';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import { registerLocale } from "react-datepicker";
import ApexCharts from 'apexcharts'
import Charts from "react-apexcharts"
import Select, { components } from 'react-select';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { options1, options2, options3, options4, stylesSelect } from './options'

import "react-datepicker/dist/react-datepicker.css";
import 'react-loading-skeleton/dist/skeleton.css'
import ru from 'date-fns/locale/ru';

import img_arrow from "../../images/arrow.png"
import sorter from '../../images/sorter.png'

const SkeletonHolder = (
    <SkeletonTheme baseColor="rgba(31, 31, 31, 0.5)" highlightColor="rgba(60, 60, 60, 0.5)">
        <Skeleton height="100%" width="100%" />
    </SkeletonTheme>
)

export const HistoryPage = () => {
    const [Items, SetItems] = useState([]);
    const [ProfitSwitch, SetProfitSwitch] = useState(true);
    const [CurrentColumn, SetCurrentColumn] = useState("date_buy");
    const [Charts, SetCharts] = useState(null);
    const [DatesButtonId, SetDatesButtonId] = useState(1);
    const [Loading, SetLoading] = useState(true);
    const [FilterAll, SetFilterAll] = useState(false);
    const [PercentExpand, SetPercentExpand] = useState(0);
    const [UserId, SetUserId] = useState(0);
    const [FirstSale, SetFirstSale] = useState(new Date(2020, 0, 1));
    const [TopIndices, SetTopIndices] = useState({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"});
    const navigate = useNavigate();

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const date = new Date();
    // const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), 0, 1));
    const [endDate, setEndDate] = useState(new Date());

    function RemoveProfitActive(e) {
        if (e.target.id !== "profit_expander") SetPercentExpand(0)
    }

    useEffect(() => {
        let chart1 = new ApexCharts(document.querySelector("#history_chart_sales"), options1);
        let chart2 = new ApexCharts(document.querySelector("#history_chart_profits"), options2);
        let chart3 = new ApexCharts(document.querySelector("#history_chart_sales_dates"), options3);
        let chart4 = new ApexCharts(document.querySelector("#history_chart_sales_duration"), options4);
        SetCharts({chart1: chart1, chart2: chart2, chart3: chart3, chart4: chart4})

        chart1.render();
        chart2.render();
        chart3.render();
        chart4.render();

        // LoadHistory(charts, dateStart, dateEnd);
        // setTimeout(() => {LoadHistory(charts, dateStart, dateEnd)}, 3000000)
        window.scrollTo(0, 0);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        document.addEventListener("click", RemoveProfitActive)
    }, [])

    async function DatesButton(e) {
        let id = e.target.id;
        const date = new Date();
        const dateEnd = new Date();
        setEndDate(dateEnd);
        if (id === "1") {
            const dateStart = new Date(date.getFullYear(), 0, 1);
            setStartDate(dateStart);
            SetDatesButtonId(1);
        }
        else if (id === "2") {
            const dateStart = new Date(date.getFullYear(), date.getMonth(), 1);
            setStartDate(dateStart);
            SetDatesButtonId(2);
        }
        else if (id === "3") {
            const dateStart = new Date();
            setStartDate(dateStart);
            SetDatesButtonId(3);
        }
        else if (id === "4") {
            const dateStart = FirstSale;
            setStartDate(dateStart);
            SetDatesButtonId(4);
        }
    }

    async function LoadHistory(charts, dateStart, dateEnd, sort = "date_buy", direction="desc") {
        try {
            let acc_opt;
            let acc_flag = false;
            if (selectedOptionsAccounts.length !== 0){
                console.log(2)
                acc_opt = "&accounts=" + selectedOptionsAccounts.map((option) => option.value).join(",");
                acc_flag = true;
            }

            let markets_buy_opt;
            let markets_buy_flag = false;
            if (selectedOptionsMarketsBuy.length !== 0){
                console.log(2)
                markets_buy_opt = "&markets_buy=" + selectedOptionsMarketsBuy.map((option) => option.value).join(",");
                markets_buy_flag = true;
            }

            let r = await fetch(`https://artempos.ru/api/history?key=${Cookies.get("session")}&sort=${sort}&direction=${direction}&date_start=${dateStart.getTime()}&date_end=${dateEnd.getTime()}${acc_flag ? acc_opt : ''}${markets_buy_flag ? markets_buy_opt : ''}&all=${FilterAll ? "yes": "no"}`);
            r = await r.json();
            if (r.status) {

                let accounts = []
                r.data.accounts.forEach(account => {
                    accounts.push({value: account, label: account})
                });
                setOptionsAccounts(accounts);

                let markets_buy = []
                r.data.markets_buy.forEach(market_buy => {
                    markets_buy.push({value: market_buy, label: market_buy})
                });
                setOptionsMarketsBuy(markets_buy);

                SetItems([...r.data.items.sort((a, b) => {
                    let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                    if (a.timestamp_buy === null) {
                        if (days_diff_a < days_diff_b) return 1
                        else return -1
                    }
                    if (b.timestamp_buy === null) return 1
                    if (a.timestamp_buy === b.timestamp_buy) return 0
                    if (a.timestamp_buy > b.timestamp_buy) return -1
                    if (a.timestamp_buy < b.timestamp_buy) return 1
                })]);
                SetLoading(false);
                SetFirstSale(new Date(r.data.first_sale*1000))
                SetTopIndices({profit_percent: r.data.top.profit_percent, profit_cash: r.data.top.profit_cash, sales: r.data.top.sales, volume: r.data.top.volume, round: r.data.top.round})
                SetUserId(r.data.user_id)

                let max_volumes = 0;
                let volumes = r.data.volume.map((i) => {
                    if (i[0] > max_volumes) max_volumes = i[0];
                    return {x: i[2]*1000, y: i[0]}
                });
                let max_profits = 0;
                let profits = r.data.volume.map((i) => {
                    if (i[1] > max_profits) max_profits = i[1];
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart1.updateSeries([
                    {name: "Объем продаж", data: volumes},
                    {name: "Прибыль", data: profits},
                ])  

                // charts.chart1.updateSeries([
                //     {name: "Объем продаж", data: data1},
                //     {name: "Прибыль", data: r.data.volume.profit}
                // ]);
                charts.chart1.updateOptions({
                    yaxis: [
                        {show: false, min: -max_volumes/2},
                        {show: false, max: max_profits*2, opposite: true}
                    ],
                })

                let percent_1 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[0]}
                });
                let percent_2 = r.data.percents.map((i) => {
                    return {x: i[2]*1000, y: i[1]}
                });
                charts.chart2.updateSeries([
                    {
                        name: "Процент прогнозируемый",
                        data: percent_1
                    },
                    {
                        name: "Процент фактический",
                        data: percent_2
                    },
                ]);
                charts.chart3.updateSeries([
                    {
                        name: "Продажи",
                        data: r.data.sales
                    },
                    {
                        name: "Неуспешные продажи",
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    },
                ]);
                charts.chart4.updateSeries(r.data.duration);
            } else {
                if (r.error === "wrong session") navigate('/login', { replace: true });
                toast.error(r.error);
            }
        } catch (error) {
            toast.error("Не получилось выполнить запрос, ошибка: " + error);
        }
    }

    const gradentParams = ['#FF0000', '#EBFF00', 'BDFF00', '#90EE90', '#00FFB2'];
    const colorCellFromValue = (params, value, midpoint = 29) => {
        value += 2;
        if (value <= 0) {
            value = 1;
        }
        return new Gradient()
          .setColorGradient(...params)
          .setMidpoint(midpoint).getColor(value);
      };

    function Sort(id) {
        console.log(Items)

        if (id === CurrentColumn) {
            if (id === "name") SetItems([...Items.sort((a, b) => a.name < b.name ? 1 : -1)]);
            else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
                let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                if (a.timestamp_buy === null) {
                    if (days_diff_a > days_diff_b) return 1
                    else return -1
                }
                if (b.timestamp_buy === null) return 1
                if (a.timestamp_buy === b.timestamp_buy) return 0
                if (a.timestamp_buy > b.timestamp_buy) return 1
                if (a.timestamp_buy < b.timestamp_buy) return -1
            })]);
            else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell > b.timestamp_sell ? 1 : -1)]);
            else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy < b.price_buy ? 1 : -1)]);
            else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell < b.price_sell ? 1 : -1)]);
            else if (id === "profit") {
                if (ProfitSwitch) SetItems([...Items.sort((a, b) => a.profit_cash < b.profit_cash ? 1 : -1)]);
                else SetItems([...Items.sort((a, b) => a.profit_percent < b.profit_percent ? 1 : -1)]);
            } 
            else if (id === "account") SetItems([...Items.sort((a, b) => a.account < b.account ? 1 : -1)]);
            else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy < b.market_buy ? 1 : -1)]);
            else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
                if (a.market_sell === b.market_sell) return 0
                if (a.market_sell < b.market_sell) return 1
                if (a.market_sell > b.market_sell) return -1
                if (a.market_sell === null) return -1
                if (b.market_sell === null) return 1
            })]);
            SetCurrentColumn("");
        } else {
            if (id === "name") SetItems([...Items.sort((a, b) => a.name > b.name ? 1 : -1)]);
            else if (id === "date_buy") SetItems([...Items.sort((a, b) => {
                let days_diff_a = Math.floor((a.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                let days_diff_b = Math.floor((b.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                if (a.timestamp_buy === null) {
                    if (days_diff_a < days_diff_b) return 1
                    else return -1
                }
                if (b.timestamp_buy === null) return 1
                if (a.timestamp_buy === b.timestamp_buy) return 0
                if (a.timestamp_buy > b.timestamp_buy) return -1
                if (a.timestamp_buy < b.timestamp_buy) return 1
            })]);
            else if (id === "date_sell") SetItems([...Items.sort((a, b) => a.timestamp_sell < b.timestamp_sell ? 1 : -1)]);
            else if (id === "price_buy") SetItems([...Items.sort((a, b) => a.price_buy > b.price_buy ? 1 : -1)]);
            else if (id === "price_sell") SetItems([...Items.sort((a, b) => a.price_sell > b.price_sell ? 1 : -1)]);
            else if (id === "profit") {
                if (ProfitSwitch) SetItems([...Items.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1)]);
                else SetItems([...Items.sort((a, b) => a.profit_cash > b.profit_cash ? 1 : -1)]);
            }
            else if (id === "account") SetItems([...Items.sort((a, b) => a.account > b.account ? 1 : -1)]);
            else if (id === "market_buy") SetItems([...Items.sort((a, b) => a.market_buy > b.market_buy ? 1 : -1)]);
            else if (id === "market_sell") SetItems([...Items.sort((a, b) => {
                if (a.market_sell === b.market_sell) return 0
                if (a.market_sell > b.market_sell) return 1
                if (a.market_sell < b.market_sell) return -1
                if (a.market_sell === null) return 1
                if (b.market_sell === null) return -1
            })]);
            SetCurrentColumn(id);
        }
    }

    function Sort_handler(e) {
        // console.log(e.currentTarget);
        const id = e.currentTarget.getAttribute("column");
        // console.log(id, CurrentColumn);
        Sort(id)
    }

    let percent_flag = false

    const items = Items.map((item) => {
        let place_time_buy
        let place_color = "rgb(255, 255, 255)"
        if (item.date_buy) {
            place_time_buy = item.date_buy
        } else {
            if (item.time_buy_tradeban) {
                let days_diff = Math.floor((item.time_free_tradeban-(new Date().getTime() - new Date().getTime()%86400000)/1000)/60/60/24)
                place_time_buy = `${item.time_buy_tradeban} (${days_diff})`
                place_color = "#9C9C9C"
            } else {
                place_time_buy = "-"
            }
        }

        return (
            <tr className={classes.item} key={item.id}>
                <th style={{textAlign: "left", paddingLeft: 0}}>{item.name}</th>
                <td style={{color: place_color}}>{place_time_buy}</td>
                <td>{item.date_sell ? item.date_sell : "-"}</td>
                <td>{item.price_buy}{item.currency_buy === "USD" ? "$" : "¥"}</td>
                <td style={{ opacity: item.date_sell ? 1 : 0.4 }}>{item.price_sell ? item.currency_sell === "USD" ? item.price_sell+"$" : item.price_sell+"¥" : "-"}</td>
                <td style={{position: 'relative'}}>
                    <div
                        id="profit_expander"
                        style={
                            {
                                display: 'flex',
                                width: 'fit-content',
                                cursor: 'pointer',
                                minWidth: '0',
                                boxSizing: 'border-box',
                                alignItems: 'center',
                                color: item.profit_percent !== null ? colorCellFromValue(gradentParams, item.profit_percent) : '',
                                opacity: item.date_sell ? 1 : 0.4,
                                transition: 'opacity 0.3s, background-color 0.3s, border-radius 0.3s',
                            }
                        }
                        className={item.id === PercentExpand ? classes.percent_active : ''}
                        onClick={() => {SetPercentExpand(item.id)}}
                    >
                    {
                        item.id === PercentExpand && (
                            item.market_profits.sort((a, b) => a.profit_percent > b.profit_percent ? 1 : -1).sort((a, b) => a.important === false ? 1 : -1).map((el) => {
                                if (!el.important && percent_flag === false) {
                                    percent_flag = true
                                    return (
                                        <>
                                            <div style={{ width: '100%', height: '1px', backgroundColor: '#9C9C9C', padding: '0', margin: '4px 0' }}></div>
                                            <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                                        </>                                        
                                    )
                                } else {
                                    return <div style={{ color: '#FFFFFF' }}><span style={{color: colorCellFromValue(gradentParams, el.profit_percent)}}>{el.profit_percent}%</span><span> - {el.market}</span></div>
                                }
                            })
                        )
                    }{
                        item.id !== PercentExpand && (
                            item.profit_percent !== null ? ProfitSwitch ? item.profit_percent + "%" : item.currency_sell === "USD" ? item.profit_cash + "$": item.profit_cash + "¥" : "-" 
                        )
                    }{
                        item.id !== PercentExpand && <img id="profit_expander" style={{ height: '20px', filter: 'invert(100%)', transform: 'rotate(180deg)' }} src={img_arrow} />
                    }
                    </div>
                </td>
                <td>{item.account}</td>
                <td>{item.market_buy ? item.market_buy : "-"}</td>
                <td style={{paddingRight: 0}}>{item.market_sell ? item.market_sell : "-"}</td>
            </tr>
        )
    })

    const [optionsAccounts, setOptionsAccounts] = useState([])
    const [selectedOptionsAccounts, setSelectedOptionsAccounts] = useState([])
    const [optionsMarketsBuy, setOptionsMarketsBuy] = useState([])
    const [selectedOptionsMarketsBuy, setSelectedOptionsMarketsBuy] = useState([])

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            let date1 = new Date(Math.min.apply(null, [startDate, endDate]))
            let date2 = new Date(Math.max.apply(null, [startDate, endDate]))
            date1.setHours(0, 0, 0)
            date2.setHours(23, 59, 59)

            SetLoading(true);
            SetTopIndices({profit_percent: "-", profit_cash: "-", sales: "-", volume: "-", round: "-"})
            
            if (Charts !== null) {
                LoadHistory(Charts, date1, date2);
            }
        }

    }, [Charts, FilterAll, startDate, endDate, selectedOptionsAccounts, selectedOptionsMarketsBuy, DatesButtonId])

    const onDatesChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        console.log(start, end)
        if (start !== null && end !== null) {SetDatesButtonId(10);}
      };
    
    return (
        <main>
            <ToastContainer autoClose={2000} position='top-left' draggablePercent={40}/>
            <div className={classes.wrapper}>
                <div className={classes.content}>
                    <div className={classes.head}>
                        <div className={classes.top}>
                            <div className={classes.calendar_holder}>
                                <span>Период:</span>
                                <div className={classes.calendar_buttons}>
                                    <button id="4" onClick={DatesButton} className={DatesButtonId === 4 ? classes.active : ""}>Всё</button>
                                    <button id="1" onClick={DatesButton} className={DatesButtonId === 1 ? classes.active : ""}>Год</button>
                                    <button id="2" onClick={DatesButton} className={DatesButtonId === 2 ? classes.active : ""}>Месяц</button>
                                    <button id="3" onClick={DatesButton} className={DatesButtonId === 3 ? classes.active : ""}>День</button>
                                    <div style={{display: "flex", height: 26, width: 1, backgroundColor: "#828282", margin: "0 10px 0 6px"}}></div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {SetDatesButtonId(10);setStartDate(date)}}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={FirstSale}
                                        maxDate={date}
                                        calendarClassName = "history_page_calendar"
                                        // dayClassName={(date) => classes.calendar_days}
                                        locale={ru}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    <div style={{display: "flex", height: 1, width: 10, backgroundColor: "#828282", margin: "0 8px"}}></div>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {SetDatesButtonId(10);setEndDate(date)}}
                                        selectsEnd
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={FirstSale}
                                        maxDate={date}
                                        calendarClassName = "history_page_calendar"
                                        // dayClassName={(date) => classes.calendar_days}
                                        locale={ru}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                </div>
                                
                            </div>
                            <div className={classes.calendar_statistic}>
                                <span>Общие показатели:</span>
                                <div className={classes.statistic}>
                                   <span>Прибыль %</span>
                                   <span>{TopIndices.profit_percent}</span>
                                </div>
                                <div className={classes.statistic} style={{width: 85}}>
                                   <span>Прибыль $</span>
                                   <span>{TopIndices.profit_cash}</span> 
                                </div>
                                <div className={classes.statistic}>
                                   <span>Продажи</span>
                                   <span>{TopIndices.sales}</span>
                                </div>
                                <div className={classes.statistic}>
                                   <span>Объем продаж</span>
                                   <span>{TopIndices.volume}</span>
                                </div>
                                <div className={classes.statistic} style={{width: 85}}>
                                   <span>Круг</span>
                                   <span>{TopIndices.round}</span>
                                </div>
                            </div>
                            <div className={classes.accounts}>
                                <span>Фильтр:</span>
                                <Select
                                    styles={stylesSelect} 
                                    className="select-custom-class"
                                    closeMenuOnSelect={false}
                                    onChange={(opt) => {setSelectedOptionsAccounts(opt)}}
                                    defaultValue={selectedOptionsAccounts}
                                    options={optionsAccounts}
                                    isMulti
                                    maxMenuHeight={200}
                                    components={{ 
                                        NoOptionsMessage(props) {
                                            return (
                                            <components.NoOptionsMessage {...props}>
                                                <span>Больше нет аккаунтов</span> 
                                            </components.NoOptionsMessage>
                                            );
                                        }
                                    }}
                                    placeholder={'Аккаунты'}
                                />
                                <button onClick={() => {SetFilterAll(!FilterAll)}} className={FilterAll ? classes.active : ""} style={UserId !== 1 ? {display: "None"} : {}}>Всё</button>
                                <Select
                                    styles={stylesSelect} 
                                    className="select-custom-class"
                                    closeMenuOnSelect={false}
                                    onChange={(opt) => {setSelectedOptionsMarketsBuy(opt)}}
                                    defaultValue={selectedOptionsMarketsBuy}
                                    options={optionsMarketsBuy}
                                    isMulti
                                    maxMenuHeight={200}
                                    components={{ 
                                        NoOptionsMessage(props) {
                                            return (
                                            <components.NoOptionsMessage {...props}>
                                                <span>Больше нет площадок</span> 
                                            </components.NoOptionsMessage>
                                            );
                                        }
                                    }}
                                    placeholder={'Покупка'}
                                />
                            </div>
                        </div>
                        <div className={classes.graphs_holder}>
                            <DatePicker
                                selected={startDate}
                                onChange={onDatesChange}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                calendarClassName = "history_page_calendar history_page_calendar2"
                                dayClassName={(date) => classes.calendar_days}
                                locale={ru}
                                dateFormat="dd/MM/yyyy"
                            />
                            <div className={classes.chart_profits_holder}>
                                {Loading && SkeletonHolder}
                               
                                <div id="history_chart_profits">
                                </div>
                            </div>
                            <div className={classes.chart_sales_dates_holder}>
                                {Loading && SkeletonHolder}
                                <div id="history_chart_sales_dates">
                                </div>
                            </div>
                            <div className={classes.chart_sales_duration_holder}>
                                {Loading && SkeletonHolder}
                                <div id="history_chart_sales_duration">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.chart_sales_holder}>
                        {Loading && SkeletonHolder}
                        <div id='history_chart_sales'></div>
                    </div>
                    <div className={classes.table_options_holder}>
                        <span>История продаж</span>
                        <div className={classes.table_options}>
                            <div className={classes.option} onClick={() => SetProfitSwitch(!ProfitSwitch)} action="percent/cash">%/$</div>
                        </div>
                    </div>
                    <div className={classes.table_holder}>
                        <table className={classes.table}>
                            <thead className={classes.thead}>
                                <tr>
                                    <th column="name" onClick={Sort_handler} style={{textAlign: "left", paddingLeft: 0}}><span>Название</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="date_buy" onClick={Sort_handler}><span>Дата покупки</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="date_sell" onClick={Sort_handler}><span>Дата продажи</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="price_buy" onClick={Sort_handler}><span>Покупка</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="price_sell" onClick={Sort_handler}><span>Продажа</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="profit" onClick={Sort_handler}><span>Прибыль</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="account" onClick={Sort_handler}><span>Аккаунт</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="market_buy" onClick={Sort_handler}><span>Куплен</span><img src={sorter} className={classes.sorter}/></th>
                                    <th column="market_sell" style={{paddingRight: 0}} onClick={Sort_handler}><span>Продан</span><img src={sorter} className={classes.sorter}/></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    )
}